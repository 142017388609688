import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import DE_LOGO from "../countries/Deutschland.png";
import FR_LOGO from "../countries/France.png";
import PT_LOGO from "../countries/Portugal.png";
import NL_LOGO from "../countries/Netherlands.png";
import IT_LOGO from "../countries/Italia.png";
import BE_LOGO from "../countries/Belgium.png";
// import OST_LOGO from "../countries/Oesterreich.png";
import { STORE as M2_STOTE, STORE_LANGUAGES as M2_STORE_LANGUAGES } from "../constants/storeCode";
import useGlobal from "../state/useGlobal";

const countries = [
  {
    id: 1,
    name: "Deutschland",
    avatar: DE_LOGO,
  },
  {
    id: 4,
    name: "France",
    avatar: FR_LOGO,
  },
  {
    id: 2,
    name: "Portugal",
    avatar: PT_LOGO,
  },
  {
    id: 6,
    name: "Netherlands",
    avatar: NL_LOGO,
  },
  {
    id: 3,
    name: "Italia",
    avatar: IT_LOGO,
  },
  {
    id: 14,
    name: "Belgium - NL",
    avatar: BE_LOGO,
  },
  {
    id: 15,
    name: "Belgium - FR",
    avatar: BE_LOGO,
  },
  {
    id: 16,
    name: "Belgium - BE",
    avatar: BE_LOGO,
  },
  // {
  //   id: 5,
  //   name: "Österreich",
  //   avatar: OST_LOGO,
  // },
];

export default function CountrySwitcher({ onChange }) {
  const { i18n } = useTranslation();
  /* eslint-disable no-unused-vars */
  const [globalState, globalActions] = useGlobal();
  var defaultCountry = countries.find((country) => {
    return country.id === globalState.store;
  });
  const [selected, setSelected] = useState(defaultCountry);

  useEffect(() => {
    i18n.changeLanguage(M2_STORE_LANGUAGES[globalState.store]);
  }, [globalState, i18n]);

  const handleOnChange = (e) => {
    globalActions.setStore(e.id);
    setSelected(e);
    i18n.changeLanguage(M2_STORE_LANGUAGES[e.id]);
    onChange(e.id);
  };

  return (
    <Listbox value={selected} onChange={handleOnChange}>
      {({ open }) => (
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500 sm:text-sm">
            <span className="flex items-center">
              <img
                src={selected.avatar}
                alt=""
                className="flex-shrink-0 w-6 h-6 rounded-full"
              />
              <span className="block ml-3 truncate">{selected.name}</span>
            </span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <SelectorIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              static
              className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
            >
              {countries.map((country) => (
                <Listbox.Option
                  key={country.id}
                  className={({ active }) =>
                    classNames(
                      "cursor-default select-none relative py-2 pl-3 pr-9",
                      {
                        "text-white bg-teal-600": active,
                        "text-gray-900": !active,
                      }
                    )
                  }
                  value={country}
                >
                  {({ selected, active }) => (
                    <>
                      <div className="flex items-center">
                        <img
                          src={country.avatar}
                          alt=""
                          className="flex-shrink-0 w-6 h-6 rounded-full"
                        />
                        <span
                          className={classNames("ml-3 block truncate", {
                            "font-semibold": selected,
                            "font-normal": !selected,
                          })}
                        >
                          {country.name}
                        </span>
                      </div>

                      {selected ? (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            {
                              "text-white": active,
                              "text-teal-600": !active,
                            }
                          )}
                        >
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}
